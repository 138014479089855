
import React, { useEffect } from "react";
import { WindupChildren,Pace } from "windups";

import Aos from "aos";
import "aos/dist/aos.css";


const Home = () => {


useEffect(function () {
Aos.init({ duration: 1000 });
}, []);

return (
<>
  {/* Banner */}
  <div className="top-banner">
    <section className="banner" id="home">
      <div className="container" data-aos="fade-up">
        <div className="row align-items-center mt-lg-5 pt-md-5 pt-4">
          <div className="col-md-7 col-lg-6">
            <h2>Welcome to ZenDEX</h2>
            <div className="social-icons pt-5">
              <p className="mb-0">Creating a user-friendly trading platform with a Low fee &
                capital-efficient DeFi solution</p>
            </div>
            <div className="float-btn py-5">
              <a href="https://app.zendex.finance/" target={"_blank"} rel="noreferrer"
                className="primary-btn gap-2">EXPLORE</a>
            </div>
            <div className="d-flex align-items-center">
              <h1 className="mb-0 pe-lg-4">BUILT ON</h1>
              <img src="assets/manta.svg" className="zeta" alt="" />
            </div>
          </div>

          <div className="col-md-5 col-lg-6 sm-bg">
            <img src="assets/banner.png" className="w-100 d-md-block d-none" alt="" />
            <img src="assets/banner-sm.png" className="w-100 d-block d-md-none" alt="" />

          </div>

        </div>
      </div>


    </section>
  </div>

  <section className="mb-5 pb-5">
    <div className="container mb-lg-5 pb-lg-5">
      <div className="row justify-content-center">
        <div className="col-md-12 mb-4 mb-lg-5 text-md-center">
        <h2>What is ZenDEX?</h2>
        <p>A hyper-efficient decentralized crypto marketplace built on Manta Pacific</p>
        </div>
        <div className="col-md-5 mb-4">
          <div className="zen-card">
            <div className="zen-img"><img src="/assets/zen1.png" className="w-100" alt="" /></div>
            <div className="zen-detail">
              <h4>Trade</h4>
              <ul>
                <li>Trade any crypto on Manta Pacific</li>
                <li>Best prices are offered through aggregating liquidity from our concentrated liquidity market
                   maker algorithm</li>
              </ul>
              <div className="zen-btn">
              <a href="https://app.zendex.finance/" target={"_blank"} rel="noreferrer"
                className="primary-btn gap-2">Trade <img src="assets/arrow.svg" height={18} alt="" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 mb-4">
          <div className="zen-card">
            <div className="zen-img"><img src="/assets/zen2.png" className="w-100" alt="" /></div>
            <div className="zen-detail">
              <h4>Earn</h4>
              <ul className="">
                <li>Provide liquidity and earn fees from trades on ZenDEX</li>
                <li>Flexible liquidity provision modes cater users from beginner to professional levels</li>
              </ul>
              <div className="zen-btn">
              <a href="https://app.zendex.finance/" target={"_blank"} rel="noreferrer"
                className="primary-btn gap-2">Earn <img src="assets/arrow.svg" height={18} alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



  <section className="mb-5 pb-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12 mb-5 pb-lg-5 text-md-center">
        <h2>Why ZenDEX?</h2>
        </div>
        </div>
        <div className="table-responsive">

      <div className="row gap-4 justify-content-center w-850 no-wrap">
          <div className="zen-ball">
            <p>Simplest Trades,
                Best Prices</p>
          </div>

          <div className="zen-ball">
            <p>Frictionless Liquidity
                  Provision</p>
          </div>

          <div className="zen-ball">
            <p>Discovery of
                Market Trends</p>
          </div>

          <div className="zen-ball">
            <p>Non-custodial
                Platform</p>
          </div>
      </div>
      </div>


    </div>
  </section>

  <div className="bottom-bg">
  <section className="mb-5 pb-5">
    <div className="container">
      <div className="row justify-content-md-center">
        <div className="col-md-12 mb-5  text-md-center">
        <h2>Partners</h2>
        </div>

        {/* <div className="col-md-3 text-md-center col-7 mb-4">
          <img src="assets/p1.png" alt="" />
        </div>

        <div className="col-md-3 text-md-center col-7 mb-4">
          <img src="assets/p2.png" alt="" />
        </div> */}

        <div className="col-md-3 text-md-center col-7 mb-4">
          <img src="assets/p3.png" alt="" />
        </div>
        
      </div>
    </div>
  </section>


  <section className="mb-5 pb-5">
    <div className="container">
      <div className="row justify-content-md-center">
        <div className="col-md-12 mb-5  text-md-center">
        <h2>Auditors and Security Partners</h2>
        </div>

        <div className="col-md-3 text-md-center col-7">
          <img src="assets/p4.png" className="w-100" alt="" />
        </div>
        
        
      </div>
    </div>
  </section>

  <footer className="mb-lg-5 py-lg-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12 mb-5  text-center">
        <img src="assets/logo.png" height={40} alt="" />
        </div>

       
        <div className="">
             
        <ul className="d-flex justify-content-center align-items-center gap-4">
                <a href="https://zealy.io/c/zendex/" target={"_blank"} rel="noreferrer"> <img src="assets/zelay.svg" alt="" /> </a>
                <a href="http://docs.zendex.finance/" target={"_blank"} rel="noreferrer"><img src="assets/docs.svg" alt="" /> </a>
                <a href="https://medium.com/@Zendex_finance" target={"_blank"} rel="noreferrer"> <img src="assets/medium.svg" alt="" />  </a>
                <a href="https://x.com/zendex_finance" target={"_blank"} rel="noreferrer"> <img src="assets/twitter.svg" alt="" /> </a>
                <a href="http://discord.gg/zendexfinance" target={"_blank"} rel="noreferrer"><img src="assets/discord.svg" alt="" /></a>
                <a href="https://github.com/ZenDEXFinance/" target={"_blank"} rel="noreferrer"> <img src="assets/git.svg" alt="" />  </a>

            </ul>

            <p className="text-center mt-5">Copyright © 2023 ZenDEX. All rights reserved.</p>
              </div>
        
      </div>
    </div>
  </footer>


  </div>





</>
);
};

export default Home;