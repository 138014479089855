import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

const Header = () => {
  const [stickyClass, setStickyClass] = useState("relative");
  const homeNav = () => {
    if (document.body.classList.contains("home-sm")) {
      document.body.classList.remove("home-sm");
    } else {
      document.body.classList.add("home-sm");
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50
        ? setStickyClass("bg-black")
        : setStickyClass("");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <div>
      <nav className={`navbar navbar-expand-lg transition-all py-3 ${stickyClass}`}>
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="assets/logo.png" height={35} alt="" />
          </Link>
          <div className="d-lg-none d-inline-flex gap-2 align-items-center">
          
            <button className="navbar-toggler" type="button" onClick={() => {homeNav();}}>
              <span className="iconify" data-icon="humbleicons:bars"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <div className="d-lg-none d-flex justify-content-between text-end p-4">
              <Link to="/" className="navbar-brand">
                <img src="assets/logo.png" alt="" />
              </Link>
              <a href="javascript:void(0)" onClick={() => {homeNav();}} className="cross-btn text-white">
                <span className="iconify" data-icon="akar-icons:cross"></span>
              </a>
            </div>
            <ul className="navbar-nav align-items-center ms-auto px-4 pe-lg-5">
                <li className="nav-item"><a href="https://zealy.io/c/zendex/" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav(); }}> <img src="assets/zelay.svg" alt="" /> </a></li>
                <li className="nav-item"><a href="http://docs.zendex.finance/" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav(); }}><img src="assets/docs.svg" alt="" /> </a></li>
                <li className="nav-item"><a href="https://medium.com/@Zendex_finance" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav(); }}> <img src="assets/medium.svg" alt="" />  </a></li>
                <li className="nav-item"><a href="https://x.com/zendex_finance" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav(); }}> <img src="assets/twitter.svg" alt="" /> </a></li>
                <li className="nav-item"><a href="http://discord.gg/zendexfinance" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav(); }}><img src="assets/discord.svg" alt="" /></a></li>
                <li className="nav-item"><a href="https://github.com/ZenDEXFinance/" target={"_blank"} rel="noreferrer" className="nav-link" onClick={() => { homeNav(); }}> <img src="assets/git.svg" alt="" />  </a></li>


            </ul>
            <div className="d-lg-flex pt-lg-0 pt-5 px-4 px-lg-0 ">
            <a href="http://app.zendex.finance/" target={"_blank"} rel="noreferrer" className="primary-btn d-inline-flex sm-btn">
            Launch dApp on mainnet
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
